@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-primary;
    background-color: #000;
  }
  details summary::-webkit-details-marker, details summary::marker {
    display:none !important;
  }
  details > summary {
    list-style: none;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  *{
    user-select: none !important;
    /* border: 1px solid red !important; */
  }
  #acordion{
    display: flex;
    justify-content: space-between;
  }
  .grid-cols-center-3 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid-cols-center-3 > * {
    grid-column: span 2;
  }
  .grid-cols-center-3 > *:nth-last-child(1):nth-child(3n + 1) {
    grid-column-end: -3;
  }
  .grid-cols-center-3 > *:nth-last-child(2):nth-child(3n + 1) {
    grid-column-end: -4;
  }
  .grid-cols-center-3 > *:nth-last-child(1):nth-child(3n + 2) {
    grid-column-end: -2;
  }
  .map{
    height: 100vh;
    filter: grayscale(100%) invert(92%) contrast(83%);
  }
  .blog-detail{
    color: #959595;
  }
  .blog-detail h2,.blog-detail h3,.blog-detail h4,.legal-detail h2{
    font-weight: 800;
    color: #fff;
  }
  .blog-detail ul{
    list-style-type: disc;
    padding-left: 40px !important;
  }
  .blog-detail ul li{
    padding-bottom: 5px;
  }
  .blog-detail img{
    margin: 30px 0 !important;
  }
  .category_text_content p, .legal-detail p{
    margin: 5px 0 10px;
  }
  .category_text_content h2,
  .category_text_content h3,
  .category_text_content h4,
  .category_text_content h5,
  .category_text_content h6
  .legal-detail h2,
  .legal-detail h3,
  .legal-detail h4,
  .legal-detail h5,
  .legal-detail h6
  {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .category_text_content ul, .blog-detail ul, .faq_detail ul, .legal-detail ul{
    list-style: disc;
    padding-left: 40px;
  }
  .category_text_content ol, .blog-detail ol, .faq_detail ol, .legal-detail ol{
    list-style: decimal;
    padding-left: 40px;
  }
  .cky-btn-revisit, .cky-btn-revisit-wrapper, .cky-revisit-bottom-left{
    display: none !important;
  }

  .team-connection-line {
    position: relative;
    overflow: hidden;
  }

  .neon-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), #00fff9, rgba(255, 255, 255, 0.1));
    background-size: 10px;
    -webkit-box-shadow:0px 0px 105px 45px rgba(0,255,251,0.9);
    -moz-box-shadow: 0px 0px 105px 45px rgba(0,255,251,0.9);
    box-shadow: 0px 0px 105px 45px rgba(0,255,251,0.9);
    filter: blur(3px);
    animation: neon 10s infinite alternate;
  }

  .team-connection-line:nth-child(1) .neon-light {
    animation-delay: 0s;
  }

  .team-connection-line:nth-child(2) .neon-light {
    animation-delay: 0.5s;
  }

  .team-connection-line:nth-child(3) .neon-light {
    animation-delay: 1s;
  }

  .team-connection-line:nth-child(4) .neon-light {
    animation-delay: 1.5s;
  }

  @keyframes neon {
    0% {
      transform: translateY(100%);
      opacity: 0.5;
    }
    100% {
      transform: translateY(-100%);
      opacity: 1;
    }
  }
}
